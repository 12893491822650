<template>
  <div class="help fill-height">
    <v-main
      id="main"
      class="tab-section fill-height"
      data-up="sub-header"
      data-left="categories"
      data-right="filters"
      :style="`padding-top:${headerHeight}px`"
    >
      <div class="content-center">
        <div>

          <h2 class="display-1 black--text mb-6">
            Ajuda
          </h2>

          <h3 class="mb-3 black--text">Com cercar una norma?</h3>

          <p>
            Podeu cercar una norma alimentària des de la
            pestanya <strong>Disposicions</strong> per
            <strong>categories</strong> o per mitjà d'una <strong>cerca avançada</strong>.
          </p>

          <ul>
            <li>
              <h4 class="mb-3 black--text">Cerca per categories:</h4>
              <p>
                A la part esquerra de la pantalla, trobareu el menú de categories.<br>
                Fent clic a la icona <v-icon class="mx-1" color="primary">mdi-menu</v-icon> 
                podreu desplegar o contraure el menú. Les categories estan
                ordenades per <strong>àrees, claus i descriptors</strong> seguint els capítols establerts al Codi
                Alimentari Espanyol. Fent clic a la icona podreu seleccionar cada un dels
                grups de categories d'una mateixa clau o àrea.
                Seleccioneu les categories fent clic una vegada. Si feu clic a una categoria
                seleccionada, tornareu a desseleccionar-la. En el cas de clicar a una clau o un
                àrea, es seleccionarà o desseleccionarà tot el grup.
              </p>
              <v-alert style="font-size:inherit" outlined>
                <strong>Important:</strong> Sempre que s’hagi fet una cerca per categories us sortirà una indicació
                a la part superior, requadre en blau, que us indicarà el nombre de categories
                seleccionades. Recordeu sempre de desseleccionar el requadre en blau abans
                de tornar a fer una cerca El número entre parèntesi indica el nombre de normes
                relacionades.
              </v-alert>
            </li>
            <li>
              <h4 class="mb-3 black--text">Cerca avançada:</h4>
              <p>
                A la part dreta de la pantalla, trobareu el menú de cerca avançada.<br>
                Fent clic a la icona <v-icon class="mx-1" color="primary">mdi-magnify</v-icon> podreu desplegar o contraure el menú.
                Podeu cercar tant per paraules clau com filtrar els resultats per data, numero de
                disposició, numero de publicació, tipus de publicació, estat de la norma i tipus de
                norma. Després d’escollir la selecció de cerca cal clicar aplicar.
              </p>
              <p>
                En el cas de filtrar per data, si ompliu només el camp 'Des de...', es mostraran els
                resultats des de la data indicada fins a la més actual.
                En cas de omplir només el camp 'Fins...', es mostraran els resultats des dels
                registres més antics fins a la data indicada.
                Finalment, omplint els dos camps, es mostraran els resultat que estiguin dins del
                rang de dates indicades.
              </p>
              <p>
                En totes les cerques podem saber el nombre de normes relacionades en la
                indicació de la part superior central, resultats, entre parèntesi el nombre de normes
                relacionades.
              </p>
              <v-alert style="font-size:inherit" outlined>
                <strong>Important:</strong> Per realitzar noves cerques sempre cal netejar la cerca anterior,
                des del botó Neteja situat a la part superior dreta de la pantalla.
              </v-alert>
            </li>
          </ul>

          <a
            style="cursor:zoom-in"
            :href="require('../../assets/help-exemple.png')"
            target="_blank"
          >
            <img
              class="mt-6"
              style="width:100%;height:auto"
              src="../../assets/help-exemple.png"
              width="1899"
              height="899"
            />
          </a>

        </div>
      </div>
    </v-main>
  </div>
</template>

<script>
import Btn from '@/components/Btn';
import { mapState } from 'vuex';

export default {
  name: 'Help',
  components: { Btn },
  computed: mapState( 'app', [ 'headerHeight' ])
}
</script>
